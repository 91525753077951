import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {graphql} from 'gatsby';
import {Box, Flex, LayoutGrid, SEOWithQuery} from 'src/components/shared';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import styled from 'styled-components/macro';

import {CookieNoticePageQuery} from 'graphql-types';

export const query = graphql`
  query CookieNoticePage {
    doc: sanityPublicCookieNotice(_id: {eq: "publicCookieNotice"}) {
      effectiveDate(formatString: "MMM D, YYYY")
      content
    }
  }
`;

const StyledFlex: typeof Flex = styled(Flex).attrs({})`
  p,
  p span,
  li,
  a {
    background: none;
    color: ${themeGet('colors.cream')};
  }
`;

type CookieNoticePageProps = {
  data: CookieNoticePageQuery;
};

const CookieNoticePage: React.FC<CookieNoticePageProps> = ({data}) => {
  return (
    <Box backgroundColor="darkGreen">
      <LayoutGrid>
        <Box gridColumn="1/end">
          <Box textAlign="center" pt="83px" pb="43px" mb="32px">
            <Box width="150px" mb="34px" mx="auto">
              <Icon aria-label={copy.cookieNotice.ariaLabels.logo} icon={Icons.logo} color="cream" />
            </Box>
          </Box>
          <StyledFlex flexDirection="column" alignItems="center">
            {data.doc?.content ? <div dangerouslySetInnerHTML={{__html: data.doc.content}} /> : null}
          </StyledFlex>
        </Box>
      </LayoutGrid>
      <SEOWithQuery title={copy.seo.privacyTitle} noIndex />
    </Box>
  );
};

export default CookieNoticePage;
